export const PLAID_LINK_NAMES = [
  "Launched_Plaid_Checking",
  "Launched_Plaid_Savings",
  "OPEN",
  "TRANSITION_VIEW",
  "SELECT_INSTITUTION",
  "OPEN_OAUTH",
  "HANDOFF",
  "ERROR",
  "Plaid_Success_Connected_Account",
  "EXIT",
  "SUBMIT_CREDENTIALS",
];
export const PLAID_LINK_TEXT = "PlaidLink_";
export const LAUREL_ROAD_PORTAL_LINK =
  "https://www.laurelroad.com/partnerships/cache/";
export const BLACK_FRIDAY_PROMOTION_TEXT = `Black Friday Exclusive Offer: Get $100 to Boost Savings! >`;

export const TRANSACTION_IN_MOTION_MESSAGE = "EXISTING_TRANSACTION_IN_MOTION";
export const CANCEL_TRANSACTION_UNAVAILABLE_MESSAGE =
  "CANCEL_TRANSACTION_UNAVAILABLE";
