import status_done from "../assets/status_done.svg";
import status_pending from "../assets/status_pending.svg";
import status_error from "../assets/status_error.svg";
import { TransactionStatus } from "./enumStatuses";

const getFormattedTransferDate = (isoDateString: string) => {
  if (!isoDateString) {
    return "-";
  }
  const inputDate = new Date(isoDateString);
  const inputDateString = inputDate.toISOString().split("T")[0];

  const currentUTCDate = new Date(new Date().toUTCString());
  const currentUTCDateString = currentUTCDate.toISOString().split("T")[0];

  const isToday = inputDateString === currentUTCDateString;

  if (isToday) {
    return "Today";
  }

  const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getUTCDate()).padStart(2, "0");
  const year = String(inputDate.getUTCFullYear()).slice(-2);

  return `${month}/${day}/${year}`;
};

const getTransferDirectionInfo = ({
  direction,
}: {
  direction: 0 | 1;
}): { from: string; to: string } => {
  if (direction === 1) {
    return {
      from: "Checking",
      to: "Savings",
    };
  }

  return {
    from: "Savings",
    to: "Checking",
  };
};

const getStatusIcon = (status: string, isReflectedInPlaid: boolean) => {
  let statusIcon;
  switch (status) {
    case TransactionStatus.CANCELED:
      statusIcon = status_error;
      break;
    case TransactionStatus.FAILED:
      statusIcon = status_error;
      break;
    case TransactionStatus.COMPLETED:
      if (isReflectedInPlaid) {
        statusIcon = status_done;
      } else {
        statusIcon = status_pending;
      }
      break;
    case TransactionStatus.DRAFT:
    case TransactionStatus.PENDING:
    case TransactionStatus.PENDING_INCOMING:
      statusIcon = status_pending;
      break;
    default:
      statusIcon = status_error;
      break;
  }
  return statusIcon;
};

function getToolTipText(status: string) {
  switch (status) {
    case TransactionStatus.CANCELED:
    case TransactionStatus.FAILED:
      return "Transfer incomplete. A member of our team will contact you shortly.";
    case TransactionStatus.COMPLETED:
      return "Transfer complete.";
    case TransactionStatus.DRAFT:
    case TransactionStatus.PENDING:
    case TransactionStatus.PENDING_INCOMING:
      return "Transfer in progress.";
  }
  return "";
}

function getToolTipWidth(status: string) {
  switch (status) {
    case TransactionStatus.CANCELED:
    case TransactionStatus.FAILED:
      return 288;
    case TransactionStatus.COMPLETED:
      return 124;
    case TransactionStatus.DRAFT:
    case TransactionStatus.PENDING:
    case TransactionStatus.PENDING_INCOMING:
      return 134;
  }
  return 288;
}

export {
  getFormattedTransferDate,
  getTransferDirectionInfo,
  getStatusIcon,
  getToolTipText,
  getToolTipWidth,
};
