import { useState, useEffect, useContext } from "react";
import Button from "../../elements/Button/Button";
import HeaderMenu from "../../elements/HeaderMenu/HeaderMenu";
import delete_icon from "../../assets/delete_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "./editAutomation.css";
import AccountCard from "../../elements/AccountCard/AccountCard";
import notification from "../../elements/ToastNotifications/ToastNotifications";
import { resetSelectedAutomation } from "../../redux/sliceUserAutomation";
import {
  setCheckingAccountList,
  setSavingsAccountList,
} from "../../redux/sliceAccounts";
import arrows from "../../assets/arrows_vertical.png";
import arrows_red from "../../assets/arrows_red_vertical.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLazyDeleteAutomationsQuery,
  useLazyGetUserAccountsQuery,
  useModifyAutomationMutation,
} from "../../api/apiPlaid";
import DeleteQuestionsModal from "../../elements/Modals/DeleteQuestionsModal";
import BackButton from "../../elements/BackButton/BackButton";
import BalanceInput from "../../elements/BalanceInput/BalanceInput";
import NotificationBar from "../../elements/NotificationBar/NotificationBar";
// @ts-ignore
import { AuthContext } from "../../context/AuthContext";
import { useLazyGetAutomationsDataQuery } from "../../api/apiDashboard";
import { useMixpanel } from "../../helpers/mixpanel";
import { AccountType } from "../../helpers/types";
import AccountLoadingCard from "../../elements/AccountLoadingCard/AccountLoadingCard";
import { getAccountSubType } from "../../helpers/account";
import { ModalAccountsList } from "../../elements/Modals/ModalAccountList";
import { errorMessages } from "../../helpers/ConnectAccountsErrorMessages";
import {
  CANCEL_TRANSACTION_UNAVAILABLE_MESSAGE,
  TRANSACTION_IN_MOTION_MESSAGE,
} from "../../helpers/constants";

const EditAutomation = () => {
  const [showCheckingAccountsModal, setShowCheckingAccountsModal] =
    useState(false);
  const [showSavingsAccountsModal, setShowSavingsAccountsModal] =
    useState(false);
  const [showDeleteAutomationModal, setShowDeleteAutomationModal] =
    useState(false);

  const context = useContext(AuthContext);
  const { user } = context;
  const { automationId } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const mixpanel = useMixpanel();

  const [modifyAutomation, modifyResult] = useModifyAutomationMutation();
  const [getAccountsList, accountsListResult] = useLazyGetUserAccountsQuery();
  const [getAutomationsReq, getAutomationsRes] =
    useLazyGetAutomationsDataQuery();
  const [deleteAutomation, deleteResult] = useLazyDeleteAutomationsQuery();
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accountLoadMsg, setAccountLoadMsg] = useState("");
  const [isCheckingAccountError, setIsCheckingAccountError] = useState(false);
  const [accountErrorType, setAccountErrorType] = useState("");
  const [accountType, setAccountType] = useState({
    type: AccountType.CHECKING,
    clicked: false,
  });
  const [typeAcc, setTypeAcc] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [failedVerificationAccountType, setFailedVerificationAccountType] =
    useState(false);

  const [selectCheckingAcc, setSelectCheckingAcc] = useState<any>();
  const [selectSavingsAcc, setSelectSavingsAcc] = useState<any>();
  const [currentAutomation, setCurrentAutomation] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const checkingAccounts: any = useSelector<RootState>(
    (state) => state.accounts.checkingAccountList,
  );
  const savingsAccounts: any = useSelector<RootState>(
    (state) => state.accounts.savingsAccountList,
  );

  //find required automation by id, apply necessary data
  useEffect(() => {
    if (getAutomationsRes.data) {
      const automationsData = getAutomationsRes?.data?.data.automations;
      if (automationsData) {
        const editingAutomation = automationsData.find(
          (automation: any) => automation.id === automationId,
        );
        setCurrentAutomation(editingAutomation);
        setValue(editingAutomation?.fixedValue);
        setSelectCheckingAcc(editingAutomation?.checkingAccount);
        setSelectSavingsAcc(editingAutomation?.savingAccount);
        setIsLoadingData(false);
      }
    }
  }, [getAutomationsRes.data]);
  const accountLoadErrorCallback = (errorMsg: string, type: string) => {
    if (type === AccountType.CHECKING && errorMsg !== "STRANGER_ACCOUNT") {
      setAccountErrorType(AccountType.SAVINGS);
    }
    if (type === AccountType.SAVINGS && errorMsg !== "STRANGER_ACCOUNT") {
      setAccountErrorType(AccountType.CHECKING);
    }
    if (type === AccountType.CHECKING && errorMsg === "STRANGER_ACCOUNT") {
      setAccountErrorType(AccountType.CHECKING);
    }
    if (type === AccountType.SAVINGS && errorMsg === "STRANGER_ACCOUNT") {
      setAccountErrorType(AccountType.SAVINGS);
    }
    setAccountLoadMsg(errorMsg);
  };
  //make request to backend for all automations
  const getAccounts = () => {
    if (!user) {
      return;
    }
    user.getIdToken().then(async (idToken: string) => {
      await getAccountsList({ idToken });
    });
  };

  const getAutomations = () => {
    if (!user) {
      return;
    }
    setIsLoading(true);
    user.getIdToken().then(async (idToken: string) => {
      await getAutomationsReq({ idToken });
      setIsLoading(false);
    });
  };

  //add accounts to redux state
  useEffect(() => {
    if (accountsListResult.data) {
      let checkingAccounts: Array<string> = [];
      let savingsAccounts: Array<string> = [];

      // Checking account of Wealthfront bank is like savings account for the automation
      accountsListResult?.data?.data.forEach((account: any) => {
        if (account.cacheType === AccountType.SAVINGS) {
          savingsAccounts.push(account);
        } else if (account.cacheType === AccountType.CHECKING) {
          checkingAccounts.push(account);
        }
      });
      dispatch(setCheckingAccountList(checkingAccounts));
      dispatch(setSavingsAccountList(savingsAccounts));
    } else {
      return;
    }
  }, [accountsListResult.data]);

  //request all automations
  useEffect(() => {
    if (!getAutomationsRes.data) {
      getAutomations();
      getAccounts();
    }
  }, [getAutomationsRes.data]);

  useEffect(() => {
    if (
      accountLoadMsg === "STRANGER_ACCOUNT" ||
      accountErrorType === AccountType.SAVINGS ||
      accountErrorType === AccountType.CHECKING
    ) {
      handleModalClose(AccountType.CHECKING);
      handleModalClose(AccountType.SAVINGS);
    }
  }, [accountLoadMsg]);
  const isCheckingCardClicked =
    accountType.type === AccountType.CHECKING && accountType.clicked;
  const isSavingCardClicked =
    accountType.type === AccountType.SAVINGS && accountType.clicked;

  const renderErrorMessage = () => {
    const isVerifiedCheckingAccount =
      isCheckingCardClicked &&
      failedVerificationAccountType &&
      accountErrorType === AccountType.CHECKING;

    const isVerifiedSavingsAccount =
      accountErrorType === AccountType.SAVINGS &&
      isSavingCardClicked &&
      failedVerificationAccountType &&
      accountErrorType === AccountType.SAVINGS;

    const strangerAccountCheckingOrSavings =
      accountLoadMsg === "STRANGER_ACCOUNT" && !failedVerificationAccountType;

    if (
      isVerifiedCheckingAccount ||
      (isVerifiedCheckingAccount && accountLoadMsg === "STRANGER_ACCOUNT")
    ) {
      setAccountErrorType(AccountType.CHECKING);
      return errorMessages.failedVerificationCheckingAccount;
    }

    if (
      isVerifiedSavingsAccount ||
      (isVerifiedSavingsAccount && accountLoadMsg === "STRANGER_ACCOUNT")
    ) {
      setAccountErrorType(AccountType.SAVINGS);

      return errorMessages.failedVerificationSavingsAccount;
    }
    if (
      strangerAccountCheckingOrSavings &&
      accountErrorType === AccountType.CHECKING
    ) {
      setAccountErrorType(AccountType.CHECKING);
      return errorMessages.strangerAccount;
    }
    if (
      strangerAccountCheckingOrSavings &&
      accountErrorType === AccountType.SAVINGS
    ) {
      setAccountErrorType(AccountType.SAVINGS);
      return errorMessages.strangerAccount;
    }
    return errorMessage;
  };
  useEffect(() => {
    setErrorMessage(renderErrorMessage);
  }, [failedVerificationAccountType || accountLoadMsg]);

  const deleteAutomationHandler = (answerList: any) => {
    const { user } = context;
    if (!user) {
      navigation("/login");
    } else {
      user.getIdToken().then(async (idToken: string) => {
        const id = automationId;
        await deleteAutomation({ idToken, id, answerList }).then((rez) => {
          mixpanel(
            "Automation_delete_reasons_selected",
            {
              "Reasons selected": answerList,
            },
            idToken,
          );
          mixpanel("Automation_deleted", {}, idToken);
          notification({
            message: "Automation deleted.",
            type: "delete",
          });
          dispatch(resetSelectedAutomation());
          navigation("/automations");
        });
      });
    }
  };

  const onBackHandler = () => {
    navigation(-1);
  };

  const handleModalOpen = (type: string) => {
    document.body.classList.remove("reset-mui-overflow");
    if (type === AccountType.CHECKING) {
      setShowCheckingAccountsModal(true);
      mixpanel("Opened_Checking_List");
    } else if (type === AccountType.SAVINGS) {
      setShowSavingsAccountsModal(true);
      mixpanel("Opened_Savings_List");
    }
    setAccountLoadMsg("");
    setAccountErrorType("");
  };

  const handleModalClose = (type: string) => {
    document.body.classList.add("reset-mui-overflow");
    if (type === AccountType.CHECKING) {
      setShowCheckingAccountsModal(false);
    } else if (type === AccountType.SAVINGS) {
      setShowSavingsAccountsModal(false);
    }
  };

  const verifyAccountsSubtype = (accounts: any[], institution: any) => {
    if (!Array.isArray(accounts)) {
      return;
    }

    const isAccountTypeMatch = accounts.some((account) => {
      let cacheType = account.subtype;
      if (institution) {
        cacheType = getAccountSubType(
          account.subtype,
          institution?.institution_id,
        );
      }
      return cacheType === typeAcc;
    });

    if (isAccountTypeMatch) {
      setFailedVerificationAccountType(false);
      return;
    }

    //banner that user didnt connect suitable account
    if (typeAcc === AccountType.CHECKING) {
      setAccountErrorType(AccountType.CHECKING);
      setIsCheckingAccountError(true);
      setFailedVerificationAccountType(true);
    }
    if (typeAcc === AccountType.SAVINGS) {
      setAccountErrorType(AccountType.SAVINGS);
      setFailedVerificationAccountType(true);
    }
  };

  const onChangeHandler = (e: any) => {
    const val = e.target.value;
    setValue(val);
    setError(false);
  };

  const onNextHandler = () => {
    const { user } = context;
    if (!value) {
      setError(true);
      return;
    }
    const automationData = {
      checkingAccountId: selectCheckingAcc.id,
      checkingAccountPlaidId: selectCheckingAcc.plaidAccountId,
      savingAccountId: selectSavingsAcc.id,
      savingAccountPlaidId: selectSavingsAcc.plaidAccountId,
      fixedValue: +value,
    };
    if (!user) {
      navigation("/login");
    } else {
      setIsLoading(true);
      user.getIdToken().then(async (idToken: string) => {
        await modifyAutomation({
          body: automationData,
          idToken,
          automationId,
        }).then((res: any) => {
          if (res?.error?.data?.status !== "SUCCESS") {
            const error = res?.error?.data?.error;
            const isErrorMessage =
              error === TRANSACTION_IN_MOTION_MESSAGE ||
              error === CANCEL_TRANSACTION_UNAVAILABLE_MESSAGE;
            if (isErrorMessage) {
              notification({
                message:
                  "A current transfer is still in progress. Your update has been saved and will take effect once the current transfer is complete.",
                type: "success",
              });
            }
            navigation("/automations");
          } else {
            notification({
              message: "Automation updated successfully.",
              type: "success",
            });
            navigation("/automations");
          }
          setIsLoading(false);
        });
      });
    }
  };

  const prePopulateAccount = (accounts: any) => {
    if (
      accounts[0]?.cacheType === AccountType.CHECKING &&
      isCheckingCardClicked
    ) {
      setSelectCheckingAcc(accounts[0]);
    }
    if (
      accounts[1]?.cacheType === AccountType.CHECKING &&
      isCheckingCardClicked
    ) {
      setSelectCheckingAcc(accounts[1]);
    }

    if (accounts[0]?.cacheType === AccountType.SAVINGS && isSavingCardClicked) {
      setSelectSavingsAcc(accounts[0]);
    }
    if (accounts[1]?.cacheType === AccountType.SAVINGS && isSavingCardClicked) {
      setSelectSavingsAcc(accounts[1]);
    }
  };

  const onPlaidAuthSuccess = (accounts: any) => {
    prePopulateAccount(accounts);
    if (accountType.type === AccountType.CHECKING) {
      if (accounts.length > 0) {
        if (accounts[0].cacheType === AccountType.SAVINGS) {
          setAccountErrorType(AccountType.CHECKING);
          setIsCheckingAccountError(true);
        }
      }
    }

    getAccounts();
    handleModalClose(AccountType.CHECKING);
    handleModalClose(AccountType.SAVINGS);
  };

  const onClickCheckingAccountCard = () => {
    setAccountType({ type: AccountType.CHECKING, clicked: true });
    setTypeAcc(AccountType.CHECKING);
    handleModalOpen(AccountType.CHECKING);
    setFailedVerificationAccountType(false);
    setAccountLoadMsg("");
    setErrorMessage("");
  };

  const onClickSavingAccountCard = () => {
    setAccountType({ type: AccountType.SAVINGS, clicked: true });
    setTypeAcc(AccountType.SAVINGS);
    handleModalOpen(AccountType.SAVINGS);
    setFailedVerificationAccountType(false);
    setAccountLoadMsg("");
    setErrorMessage("");
  };

  return (
    <div>
      <HeaderMenu />
      <NotificationBar />
      <div className="container edit-automation-container-wrapper">
        <div className="backButton">
          <BackButton onBack={onBackHandler} />
        </div>
        <div className="edit__titleHolder">
          <div className="page__title custom_mobile">Edit your automation.</div>
          <div className="page__subtitle automation__description">
            Click on any field to edit.
          </div>
        </div>
        <div
          className="automation__container edit-automation__container"
          style={{ flex: "0" }}
        >
          <div className="connectionButton__holder">
            <div className="automations__checkingCards">
              {isLoadingData ? (
                <AccountLoadingCard />
              ) : (
                <AccountCard
                  active={true}
                  errorCard={accountErrorType === AccountType.CHECKING}
                  item={selectCheckingAcc}
                  type="Checking account"
                  reconnectionFlow={true}
                  showOnlyDisconnectedIcon={true}
                  disconnected={
                    selectCheckingAcc?.status === "reconnection_need"
                      ? true
                      : false
                  }
                  selectHandler={onClickCheckingAccountCard}
                  editFlow={true}
                />
              )}
              <div className="balanceInput__holder">
                <BalanceInput
                  error={error}
                  isdisabled={!(selectCheckingAcc && selectSavingsAcc)}
                  value={value}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              {error && (
                <div className="userName__errorMassage error__message">
                  Please set your fixed balance.
                </div>
              )}
            </div>
            <div className="arrows__icon">
              {selectCheckingAcc?.status === "reconnection_need" ||
              selectSavingsAcc?.status === "reconnection_need" ? (
                <img src={arrows_red} alt="Arrows Icon" />
              ) : (
                <img src={arrows} alt="Arrows Icon" />
              )}
            </div>
            <div className="automations__savingsCards">
              {isLoadingData ? (
                <AccountLoadingCard />
              ) : (
                <AccountCard
                  active={true}
                  errorCard={accountErrorType === AccountType.SAVINGS}
                  item={selectSavingsAcc}
                  type="Savings account"
                  reconnectionFlow={true}
                  showOnlyDisconnectedIcon={true}
                  selectHandler={onClickSavingAccountCard}
                  disconnected={
                    selectSavingsAcc?.status === "reconnection_need"
                      ? true
                      : false
                  }
                  editFlow={true}
                />
              )}
            </div>
          </div>
          <div className="userName__errorMassage error__message account-exist desktop-error">
            {errorMessage}
          </div>
        </div>

        <button
          className="deleteAutomation__btn"
          onClick={() => {
            mixpanel("Start_automation_delete_flow");
            setShowDeleteAutomationModal(true);
          }}
        >
          <div className="deletebtn_title">Delete Automation</div>
          <div className="deleteAutomation__btn-icon">
            <img src={delete_icon} alt="delete icon" />
          </div>
        </button>
        <div className="confirmButton__holder">
          <Button
            disabled={isLoading}
            onClick={onNextHandler}
            type={"primary-btn"}
          >
            Save
          </Button>
        </div>
      </div>

      {showCheckingAccountsModal && (
        <ModalAccountsList
          title="Checking Account"
          accountsList={checkingAccounts}
          open={showCheckingAccountsModal}
          relationType={AccountType.CHECKING}
          onClose={() => {
            handleModalClose(AccountType.CHECKING);
          }}
          setAccount={setSelectCheckingAcc}
          verifyAccountsSubtype={verifyAccountsSubtype}
          accountLoadErrorCallback={accountLoadErrorCallback}
          onPlaidAuthSuccess={onPlaidAuthSuccess}
          currentAutomation={currentAutomation}
        />
      )}
      {showSavingsAccountsModal && (
        <ModalAccountsList
          title="Savings Account"
          accountsList={savingsAccounts}
          open={showSavingsAccountsModal}
          relationType={AccountType.SAVINGS}
          onClose={() => {
            handleModalClose(AccountType.SAVINGS);
          }}
          setAccount={setSelectSavingsAcc}
          verifyAccountsSubtype={verifyAccountsSubtype}
          accountLoadErrorCallback={accountLoadErrorCallback}
          onPlaidAuthSuccess={onPlaidAuthSuccess}
          currentAutomation={currentAutomation}
        />
      )}
      {showDeleteAutomationModal && (
        <DeleteQuestionsModal
          onHide={() => {
            setShowDeleteAutomationModal(false);
          }}
          deleteAction={deleteAutomationHandler}
          type={"automation"}
        />
      )}
    </div>
  );
};

export default EditAutomation;
