export enum UserStatuses {
  INACTIVE = "inactive",
  INCOMPLETE = "incomplete",
  DWOLLA_VALIDATION_WAITING = "dwolla_validation_waiting",
  DWOLLA_VALIDATION_PENDING = "dwolla_validation_pending",
  UNVERIFIED_DWOLLA_RETRY = "unverified_dwolla_retry",
  UNVERIFIED_DWOLLA_FAILED = "unverified_dwolla_failed",
  ACTIVE = "active",
}

export enum AutomationStatuses {
  DRAFT = "draft",
  DISABLED = "disabled",
  RECONNECTION_NEED = "reconnection_need",
  ENABLED = "enabled",
  REMOVED = "removed",
}

export enum TransactionStatus {
  DRAFT = "draft",
  PENDING = "pending",
  PENDING_INCOMING = "pending_incoming",
  CANCELED = "canceled",
  FAILED = "failed",
  COMPLETED = "completed",
}
