export enum AccountType {
  CHECKING = "checking",
  SAVINGS = "savings",
}
export type Account = {
  id: string;
  userId: string;
  accessToken: string;
  plaidAccountId: string;
  plaidItemId: string;
  dwollaFundingSourceUrl: string | null;
  availableBalance: number;
  mask: string;
  name: string;
  officialName: string;
  subtype: string;
  cacheType: string;
  type: string;
  institution: {
    id: string | null;
    name: string | null;
    logo: string | null;
  };
  interestRate: number | null;
  routingNumber: string | null;
  wireRoutingNumber: string | null;
  accountNumber: string | null;
  status: string;
  plaidCursor: string;
  hasHistoricalBalancesUpdated: boolean;
  updatedAt: string;
  createdAt: string;
};

